import React from "react"
import axios from "axios"
import { Stack, Text, Link, FontWeights, Shimmer, IMessageBarProps } from "office-ui-fabric-react"
import { MessageBar, MessageBarType, MessageBarButton } from "office-ui-fabric-react"
import { IStyle, Label } from "office-ui-fabric-react"
import { PanelType } from "office-ui-fabric-react"

import { TextField, MaskedTextField } from 'office-ui-fabric-react/lib/TextField';
import { IPersonaSharedProps, Persona, PersonaSize, PersonaPresence} from "office-ui-fabric-react"
import { IScheme } from 'office-ui-fabric-react' 
import {
    DefaultButton,
    IButtonProps,
    PrimaryButton,
} from "office-ui-fabric-react"
import "./index.css"
import LoginDialog from "../components/login.tsx"
import SubmitArea from "../components/submit.tsx"
import { __positioningTestPackage } from "office-ui-fabric-react/lib/utilities/positioning";
import UserInfoArea from '../components/userInfoArea';
import GlobalStatus from "../components/globalStatus";
import ServerInfoArea from '../components/serverInfo';
import QueueInfoArea from '../components/queueInfo'
import { setResponsiveMode, ResponsiveMode} from "office-ui-fabric-react/lib/utilities/decorators/withResponsiveMode";
import { initializeIcons } from "office-ui-fabric-react";

function toHMS(time):string {
        //space:replace space with ' ' or '0'
        var space=" ";
        var arr = Array();
        var t = time;
        arr[0] = Math.floor(t / 3600);
        t = t % 3600;
        arr[1] = Math.floor(t / 60);
        t = t % 60;
        arr[2] = t;
        if (arr[0] < 10) arr[0] = space + arr[0].toString();
        if (arr[1] < 10) arr[1] = space + arr[1].toString();
        if (arr[2] < 10) arr[2] = space + arr[2].toString();
                var text="";
                if(arr[0]!=0)text+=arr[0].toString() + " 小时 ";
                if(arr[1]!=0)text+=arr[1].toString() + " 分钟 ";
                if(arr[2]!=0)text+=arr[2].toString() + " 秒 ";
                return text;
}

initializeIcons();
setResponsiveMode(ResponsiveMode.medium);
//setResponsiveMode(ResponsiveMode.small);
export default class App extends React.Component<{}> {
        constructor(props:{}){
                super(props);
                this.state={
                        chronos : "https://chronosapi.beacoworks.tk",
                        queueLoaded: 0,
                        queue: "",
                        estimateTime: "",
                        psaLoaded: 0,
                        psa: "",
                        configTimeLoaded: 0,
                        configTime:"",
                        configTimePrep:"",
                        configTimePerform:"",
                        configTimeExtra:"",
                        versionLoaded: 0,
                        chronosVersion: "",
                        loginDialog: false,
                        emeraldVersion: "Emerald V2 1.51 | The Sanctuary | Mobile",
                        globalNotifyStatus: MessageBarType.info,
                        globalNotifyContent: "服务初始化-即将开始获取数据。",
                        disableGlobalClassNames: true,
                };
        }
    public render(): JSX.Element {
                const { chronos, queueLoaded, psaLoaded, configTimeLoaded, versionLoaded, emeraldVersion, username,
                        queue, estimateTime, psa, configTime, chronosVersion,
                        globalNotifyStatus, globalNotifyContent,
                        configTimePrep, configTimePerform, configTimeExtra,
                        } = this.state;
        return (
                <Stack vertical id="main" gap={20}>
                        <GlobalStatus 
                                globalNotifyStatus={globalNotifyStatus} 
                                globalNotifyContent={globalNotifyContent}
                                _refresh={this._refresh} 
                                ref="GlobalStatusArea" 
                                panelType={PanelType.smallFluid}
                                chronos={chronos} 
                                doGlobalUpdate={this.update}
                                globalStatus={this.globalStatus}        
                        />
                        <UserInfoArea 
                                chronos={chronos} 
                                ref="UserInfoArea"
                                doGlobalUpdate={this.update} 
                        />
                        <ServerInfoArea 
                                chronos={chronos} 
                                chronosVersion={chronosVersion}
                                emeraldVersion={emeraldVersion} 
                                ref="ServerInfoArea" 
                                doGlobalUpdate={this.update}
                        />
                        <Stack vertical gap={10}>
                                <Stack vertical gap={10}>
                                        <Text varient="medium">
                                                取号排队前请阅读须知以及如下通知:
                                        </Text>
                                        <Shimmer isDataLoaded={psa}>
                                                <Text variant="medium">{psa}</Text>
                                        </Shimmer>
                                </Stack>
                                <Stack vertical gap={10}>
                                        <Text varient="medium">当前设定的单场排练时长为:</Text>
                                        <Shimmer isDataLoaded={configTime}>
                                                <Text varient="medium">{configTime}</Text>
                                        </Shimmer>
                                </Stack>
                        </Stack>
                        <QueueInfoArea
                                ref="QueueInfoArea"
                                chronos={chronos}
                                queue={queue}
                                estimateTime={estimateTime}
                                _updateGlobalStatus={this._updateGlobalStatus}
                                username={this.username}
                                userGroup={this.userGroup}
                                userid={this.userId}
                                isAuthenticated={this.isAuthenticated}
                                doGlobalUpdate={this.update}
                                submitLevel={this.submitLevel}
                                heartbeatUri="wss://chronosapi.beacoworks.tk/heartbeat"
                        />
                </Stack>
        )
        }
            private _getPSA = (): void => {
                var target = this.state.chronos.toString() + "/api/psa";
                        var App = this;
                        App.setState({
                                psaLoaded: 0,
                                psa: ""
                        });
                axios.get(target)
                        .then(function (response) {
                                App.setState({
                                        psaLoaded: 1,
                                        psa: response.data.PSA,
                                });
                                App._updateGlobalStatus();
                                })
                                .catch(function (error) {
                                        console.log(error);
                                        App.setState({
                                        psaLoaded: -1,
                                });
                                App._errorOccured();
                                App._updateGlobalStatus();
                        });
            }
            private _getConfigTime = (): void => {
                var target = this.state.chronos.toString() + "/api/config/timing";
                        var App = this;
                        App.setState({
                                configTimeLoaded: 0,
                                configTime: ""
                        });
                axios.get(target)
                        .then(function (response) {
                                var res = response.data;
                                var prep = res.prepTime;
                                var perform = res.mainTime;
                                var extra = res.prepExtraTime;
                                console.log(res);
                                var text = "准备时长: " + toHMS(prep) + ", 排练时长: " + toHMS(perform) + ", 额外准备时长: " + toHMS(extra);
                                App.setState({
                                        configTimeLoaded: 1,
                                        configTime: text,
                                });
                                App._updateGlobalStatus();
                                })
                                .catch(function (error) {
                                        console.log(error);
                                        App.setState({
                                                configTimeLoaded: -1,
                                        });
                                        App._errorOccured();
                                        App._updateGlobalStatus();
                                });
            }
            private _submit = (): void => {
                alert("not supported yet.");
            }
            private _updateVersion = (): void => {
                        var target = this.state.chronos.toString() + "/api/version";
                        var App = this;
                        App.setState({
                                versionLoaded: 0,
                                version: ""
                        });
                axios.get(target)
                        .then(function (response) {
                                        App.setState({
                                                versionLoaded: 1,
                                                chronosVersion: response.data.version,
                                        });
                                        App._updateGlobalStatus();
                                        App.refs["ServerInfoArea"].updateVersion(response.data.version);
                                })
                                .catch(function (error) {
                                        console.log(error);
                                        App.setState({
                                                versionLoaded: -1,
                                        });
                                        App._errorOccured();
                                        App._updateGlobalStatus();
                        });
            }
            private _errorOccured = ():void => {
                this.setState({
                        globalNotifyContent:"Error occured.",
                        globalNotifyStatus: MessageBarType.error,
                });
                this.refs["UserInfoArea"].updateIndicator();
                this.refs["GlobalStatusArea"].showPanel();
                console.log("error!!!");
            }
            private _updateGlobalStatus = ():void => {
            var hasError: Boolean = false;
       if(this.state.psaLoaded === -1){
                hasError = true;
                console.log("[ErrorBuster]PSA!")
        }
        if(this.refs["QueueInfoArea"].state.queueLoaded === -1){
                hasError = true;
                console.log("[ErrorBuster]Queue!")
        }
        if(this.state.configTimeLoaded === -1){
                hasError = true;
                console.log("[ErrorBuster]Config!")
        }
        if(this.state.versionLoaded === -1){
                hasError = true;
                console.log("[ErrorBuster]Version!")
        }
        console.log("[ErrorBuster]hasError:");
        console.log(hasError);
        if(hasError){
                this.setState({
                        globalNotifyContent:"警告-发生错误，未能获取数据。请重试。如果这不起作用，请联系系统管理员。",
                        globalNotifyStatus: MessageBarType.error,
                });
                console.log("[Emerald-Error]An error was triggered and was broadcasted to all components.");
                this.refs["UserInfoArea"].updateIndicator();
                this.refs["GlobalStatusArea"].showPanel();
        }
        else{
        var loadingInProgress: Boolean = true;
        console.log("[Emerald-Error]Load Status:");
        console.log(this.state.psaLoaded);
        console.log(this.refs["QueueInfoArea"].state.queueLoaded);
        console.log(this.state.configTimeLoaded);
        console.log(this.state.versionLoaded);
        if(     this.state.psaLoaded===1 && 
                this.refs["QueueInfoArea"].state.queueLoaded && 
                this.state.configTimeLoaded===1 &&         
                this.state.versionLoaded===1){
                loadingInProgress = false;
                console.log("[Emerald-Main]Statement update-Loading Finished.");
        }
        if(loadingInProgress){
                this.setState({
                        globalNotifyContent:"请稍后-正在加载数据。",
                        globalNotifyStatus: MessageBarType.info,
                });
                console.log("[Emerald-Main]Statement update-Loading In Progress.");
        }
        else {
                this.setState({
                        globalNotifyContent:"数据加载完成。",
                        globalNotifyStatus: MessageBarType.success,
                });
                console.log("[Emerald-Main]Statement update-Loading finished.");
                this.refs["UserInfoArea"].updateIndicator();
        }
        }
        
        this.refs["GlobalStatusArea"]._updateGlobalStatus(this.state.globalNotifyStatus,this.state.globalNotifyContent);
    }
        private _refresh =():void => {
        this.setState({
                globalNotifyContent:"请稍后-正在加载数据。",
                globalNotifyStatus: MessageBarType.info,
        });
        //this._getQueueAndEstimate();
        this._getPSA();
        this._getConfigTime();
        this._updateVersion();
    }
    componentDidMount() {
        document.title="Chronos by Beacoworks";
        //this.refs["queueInfoArea"]._getQueueAndEstimate();
        this._getPSA();
        this._getConfigTime();
        this._updateVersion();
        this.setState({
          userInfoArea: this.refs["UserInfoArea"]
        })
    }
    username = ():String => {
      return this.refs["UserInfoArea"].state.username;
    }
    userGroup = ():String => {
      return this.refs["UserInfoArea"].state.topUserGroup;
    }
    userId = ():String => {
      return this.refs["UserInfoArea"].state.userId;
    }
    isAuthenticated = ():Boolean => {
      return this.refs["UserInfoArea"].state.isAuthenticated;
    }
    submitLevel = ():any => {
      return this.refs["UserInfoArea"].state.submitLevel;
    }
    update = ():void => {
      console.log("[Emerald]Enforcing an global update for all components")
      this._refresh();
      this.refs["UserInfoArea"].update();
      this.refs["QueueInfoArea"].update();
      this.refs["ServerInfoArea"].update();
      this.refs["GlobalStatusArea"].update();
    }
    globalStatus = ():MessageBarType => {
      return this.refs["GlobalStatusArea"].globalNotifyStatus;
    }
        }
        
        //export default App
        
